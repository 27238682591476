import React, { Fragment, useEffect } from 'react';
import Loader from "../layout/loader";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import {ToastContainer} from 'react-toastify'
import { connect } from 'react-redux';
import { ActionCreators } from '../redux/actions/statistics';

import Socket from './Socket';
import Events from '../services/Events';

const App = ({children, dispatch}) => {

  const registerPlayerCount = () => {
    Events.send({ e: 'subscribe', d: { channel: 'player-count' } });
  }

  useEffect(() => {

    Events.on('player-count', (d) => {
      console.log(d)
      dispatch(ActionCreators.set({ players: d.players, topCount: d.topcount }));
    });

  }, [dispatch]);

  return (
    <Fragment>
      <Socket onFirstConnect={registerPlayerCount} />
      <Loader/>
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header/>
      <div className="page-body-wrapper sidebar-icon">
        <Sidebar/>
        <div className="page-body">
          {children}
        </div>
        <Footer/>
        </div>
      </div>
      <ToastContainer/>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.user
  }
}

export default connect(mapStateToProps)(App);;
