// dashbaord
import Default from '../components/default/index.jsx'
import Infractions from '../components/sancties/index.jsx'
import Object from '../components/sancties/object.jsx'
import Unban from '../components/sancties/unban.jsx'

// starter kits
import Starterkits from '../components/starter-kits'

export const routes = [
        { path:`${process.env.PUBLIC_URL}/`, Component:Default},
        { path:`${process.env.PUBLIC_URL}/sancties`, Component:Infractions},
        { path:`${process.env.PUBLIC_URL}/sancties/object/:warn`, Component:Object},
        { path:`${process.env.PUBLIC_URL}/sancties/unban/:id`, Component:Unban},

        { path:`${process.env.PUBLIC_URL}/starter-kits/sample-page`, Component:Starterkits},


]
