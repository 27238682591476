import { Types } from '../actionTypes';

const initialState = {
    players: '?',
    topCount: '?',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_STATISTICS:
      console.log({
        ...state,
        ...action.payload
      })
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}

export default reducer;
