import * as React from 'react';

import Events from '../services/Events';

class Socket extends React.Component {
  static retryIn = 2000;

  static maxRetries = 3;

  static error() {
    Events.emit('WS_ERROR', {});
  }

  constructor() {
    super();

    this.state = {
      retries: 0,
      connectedAt: null,
      lastMessage: null,
    };

    this.ws = null;

    this.sendMessage = this.sendMessage.bind(this);
  }

  componentDidMount() {
    this.connectSocket();

    Events.on('sendMessage', this.sendMessage);
  }

  connectSocket() {
    this.ws = new WebSocket(process.env.REACT_APP_SOCKET_URI);

    window.ws = this.ws;

    this.ws.onopen = this.open.bind(this);
    this.ws.onclose = this.close.bind(this);
    this.ws.onerror = this.error;
    this.ws.onmessage = this.message.bind(this);
  }

  message(e) {
    let obj = null;

    try {
      obj = JSON.parse(e.data);
    } catch (err) {
      //
    }

    this.setState({ lastMessage: Date.now() });

    Events.emit(obj.e, obj.d);
  }

  open() {
    if (this.props.onFirstConnect && this.state.connectedAt === null) {
      this.props.onFirstConnect(this.state.connectedAt);
    }

    this.setState({ connectedAt: Date.now(), retries: 0 });

    setInterval(() => this.heartbeat(), 45000)

    Events.emit('WS_OPEN', { connectedAt: this.state.connectedAt });
  }

  close() {
    Events.emit('WS_CLOSE', { retries: this.state.retries, retryIn: Socket.retryIn, lastMessage: this.state.lastMessage });

    if (this.state.retries < 3) {
      setTimeout(() => {
        this.setState((oldState) => ({ retries: oldState.retries + 1 }));

        this.connectSocket();
      }, Socket.retryIn);
    }
  }

  sendMessage(msg) {
    let json = null;

    try {
      json = JSON.stringify(msg);
    } catch (e) {
      //
    }

    this.ws.send(json);
  }

  heartbeat() {
  	this.sendMessage({
  		e: 'heartbeat',
  		d: 2016
  	});
  }

  render() {
    return null;
  }
}

export default Socket;
