import React, { Fragment } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardBody,Media,Button,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Happy from '../../assets/images/happy.svg';

class Sancties extends React.Component {

  constructor() {
    super();

    this.state = {
      warns: [],
      modal: false,
    };

    this.months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    this.getInfraction = this.getInfraction.bind(this);
  }

  getInfraction(id) {
    let filtered = this.state.warns.filter(i => i.id === id);

    if (filtered.length > 0) {
      return filtered[0];
    }
    return null;
  }

  componentDidMount() {
    let react_this = this;
    axios.get('/infractions', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
    .then(function (response) {
      react_this.setState({ warns: response.data });
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  render() {
    let infraction = {};
    let expireDate = null;
    if (this.state.modal) {
      infraction = this.getInfraction(this.state.modal);
      expireDate = new Date(infraction.date);
    }


    return (
         <Fragment>
         <Breadcrumb parent="Zelf regelen" title="Sancties"/>
          <Container fluid={true}>
            <Row>

            {
              this.state.warns.length > 0
              ? this.state.warns.map(i => {
                const expiring = new Date(i.date);

                return (
                  <Col xl="4 xl-100" key={i.id}>
                      <Card style={{height: 250, position: 'relative'}}>
                          <div className="job-search">
                              <CardBody>
                                <Media>
                                    <Media body>
                                        <h6 className="f-w-600">{i.category}</h6>
                                        <p style={{ marginBottom: 0 }}>{this.type(i.type, i.weight)}, {this.date(i.dateinsert)}</p>
                                        <p style={{ marginTop: 0 }}>
                                          {
                                            new Date().getTime() < expiring.getTime() ?
                                            (
                                              expiring.getTime() !== 32535039600000 ?
                                              `Verloopt op ${expiring.getDate()} ${this.months[expiring.getMonth()]} ${expiring.getFullYear()}
                                                om ${this.hours(expiring.getHours())}:${this.hours(expiring.getMinutes())}`
                                              : null
                                            )
                                            : expiring !== null ?
                                            `Verlopen op ${expiring.getDate()} ${this.months[expiring.getMonth()]} ${expiring.getFullYear()}
                                              om ${this.hours(expiring.getHours())}:${this.hours(expiring.getMinutes())}`
                                              : null
                                          }
                                        </p>
                                    </Media>
                                </Media>
                                <div>
                                  <p>{i.reason.slice(0, 120) + (i.reason.length > 120 ? '...' : '')}</p>
                                </div>

                                <br />
                                <Button
                                  color="primary pull-right"
                                  size="sm"
                                  style={{ position: 'absolute', right: 10, bottom: 10 }}
                                  onClick={() => this.setState({ modal: i.id })}
                                  >
                                    Meer info
                                  </Button>
                              </CardBody>
                          </div>
                      </Card>
                  </Col>
                );
              })
              : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                    width: '100%',
                  }}
                  >
                  <img alt="happy" src={Happy} style={{ height: 300, marginBottom: 25 }} />
                  Je hebt geen sancties, goed bezig!
                </div>
              )
            }

            </Row>
          </Container>

          <Modal isOpen={this.state.modal !== false} className="modal-body" centered={true}>
              <ModalHeader>{infraction.category}</ModalHeader>
              <ModalBody>
                  <Container fluid={true} className="bd-example-row">
                      <p style={{ color: '#999999' }}>
                        {
                          new Date().getTime() < new Date(infraction.date) ?
                          (
                            expireDate.getTime() !== 32535039600000 ?
                            `Verloopt op ${expireDate.getDate()} ${this.months[expireDate.getMonth()]} ${expireDate.getFullYear()}
                              om ${this.hours(expireDate.getHours())}:${this.hours(expireDate.getMinutes())}`
                            : null
                          )
                          : expireDate !== null ?
                          `Verlopen op ${expireDate.getDate()} ${this.months[expireDate.getMonth()]} ${expireDate.getFullYear()}
                            om ${this.hours(expireDate.getHours())}:${this.hours(expireDate.getMinutes())}`
                            : null
                        }
                      </p>
                      <p>{infraction.reason}</p>

                  </Container>
              </ModalBody>
              <ModalFooter>
                {
                  infraction.type === 'warn' && ['1','2','3'].includes(infraction.weight) ?
                  (new Date().getTime() - 1209600000) < new Date(infraction.dateinsert) && (
                    <Link to={"/sancties/object/" + infraction.id}>
                      <Button
                        color="primary">
                        {
                          infraction.obj_status === null ?
                          'Bezwaar indienen' :
                          'Bezwaar inzien'
                        }
                      </Button>
                    </Link>
                  )
                  : infraction.type === 'ban' && (['5','7'].includes(infraction.weight)) ?
                  (
                    <Link to={"/sancties/unban/" + infraction.id}>
                      <Button
                        color="primary">
                        {
                          infraction.unban_status === null ?
                          'Unban aanvraag indienen' :
                          'Unban aanvraag inzien'
                        }

                      </Button>
                    </Link>
                  )
                  : null
                }
                  <Button
                    color="secondary"
                    onClick={() => this.setState({ modal: false })}>
                    Sluiten
                  </Button>
              </ModalFooter>
          </Modal>


         </Fragment>
    );
  }

  date(date) {
    let d = new Date(date);

    return d.getDate() + ' ' + this.months[d.getMonth()] + ' ' + d.getFullYear();
  }

  hours(hour) {
    if (hour < 10) {
      return "0" + hour;
    }
    return hour;
  }

  type(type, weight) {
    if (type === 'warn') {
      switch (weight) {
        case '1':
          return 'Lichte waarschuwing';
        case '2':
          return 'Middelzware waarschuwing';
        case '3':
          return 'Zware waarschuwing';
        case '0':
          return 'Aangesproken';
        default:
          return 'Waarschuwing';
      }
    } else if (type === 'ban') {
      switch (weight) {
        case '4':
          // temp ban
          return 'Tijdelijke verbanning';
        case '5':
          // perm
          return 'Permanente verbanning';
        case '6':
          // noteless temp
          return 'Tijdelijke verbanning zonder note';
        case '7':
          // discord perm
          return 'Permanente discord verbanning';
        case '10':
          // warn ban
          return 'Waarschuwing verbanning';
        default:
          return 'Verbanning';
      }
    } else if (type === 'warnban') {
      return 'Waarschuwing verbanning';
    }
  }

}

export default Sancties;
