import { Types } from '../actionTypes';

const initialState = {
    id: '',
    steam: '',
    username: '',
    avatar: '',
    created_at: '',
    updated_at: '',
    flag: '',
    staff: false,
    firstname: '',
    lastname: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        ...action.payload.user
      }
    default:
      return state;
  }
}

export default reducer;
