import React from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from '../redux/actions/user';
import { withRouter} from "react-router-dom";
import axios from 'axios';
import {Redirect} from 'react-router-dom'

class Startup extends React.Component {

  constructor() {
    super();

    this.state = {
      redirect: null
    }

    this.fetchUser = this.fetchUser.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser() {
    if (localStorage.getItem('token')) {
      let react_this = this;
      axios.get('/user', {
        headers: {
          authorization: localStorage.getItem('token')
        }
      })
      .then(function (response) {
        react_this.props.dispatch(ActionCreators.login(response.data));
      })
      .catch(function (error) {
        console.log(error)
        localStorage.removeItem('token');
        if (window.location.pathname !== '/login') react_this.setState({ redirect: '/login' });
      });
    } else if (window.location.pathname !== '/login') {
      this.setState({ redirect: '/login' });
    }
  }

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;
    return null;
  }

}

function mapStateToProps(state) {
  return {
    profile: state.user
  };
}

export default connect(mapStateToProps)(withRouter(Startup));
