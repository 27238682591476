import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap';
import { Clock } from 'react-feather';
import { useSelector } from 'react-redux';
import axios from 'axios';

import quotes from '../../assets/objects/quotes.json';

import InfoBackground from '../../assets/images/infoBackground.png';
import UserBackground from '../../assets/images/userBackground.png';

const Home = () => {
    const [time, setTime] = useState(new Date());
    const [quote, setQuote] = useState('');
    const [vehicles, setVehicles] = useState(0);
    const [billing, setBilling] = useState(0);
    const [playtime, setPlayTime] = useState({});
    const players = useSelector(state => state.statistics.players);
    const topCount = useSelector(state => state.statistics.topCount);
    const profile = useSelector(state => state.user);

  useEffect(() => {
    setInterval(updateTime, 10000);
    setQuote(quotes[Math.floor(Math.random() * quotes.length)].quote)

    axios.get('/statistics', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
    .then(function (response) {
      setVehicles(response.data.vehicles);
      setBilling(response.data.billing);
    })
    .catch(function (error) {
      console.log(error)
    });

    axios.get('/playtime', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
    .then(function (response) {
      setPlayTime(response.data);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, []);

  const updateTime = () => {
    setTime(new Date());
  }

  const rewards = {
    0: 'Burger',
    20: 'Kennis',
    30: 'Bekende',
    40: 'Inwoner',
    50: 'Erkend inwoner',
    100: 'Ereburger'
  };

  let role;
  Object.keys(rewards).forEach(item => {
    if (playtime.days > item) {
      role = {
        role: rewards[item],
        days: item
      };
    }
  });

  const getNextRole = () => {
    let role;
    Object.keys(rewards).forEach(item => {
      if (playtime.days > item) role = item;
    });

    return {
      role: rewards[  Object.keys(rewards)[Object.keys(rewards).indexOf(role) + 1] ],
      days: Object.keys(rewards)[Object.keys(rewards).indexOf(role) + 1]
    };
  }

  const nextRole = getNextRole();

  let percentage;
  if (nextRole && role && playtime) {
    const needed = nextRole.days - role.days;
    const have = playtime.days - role.days;

    percentage = have / (needed / 100);
  }
  return (
       <Fragment>
       <Breadcrumb parent="Algemeen" title="Home"/>
        <Container fluid={true}>
          <Row className="second-chart-list third-news-update">

              <Col xl="4 xl-50" lg="12" className="account-sec box-col-12">
                <Card className="o-hidden profile-greeting" style={{backgroundImage: `url(${UserBackground})`, height: 400 }}>
                  <CardBody>
                    <div className="media">
                      <div className="badge-groups w-100">
                        <div className="badge f-12">
                          <Clock style={{width:"16px" ,height:"16px"}} className="mr-1"/>
                          <span id="txt">{time.getHours()}:{time.getMinutes() < 10 ? "0"+time.getMinutes() : time.getMinutes()}</span>
                        </div>
                      </div>
                    </div>
                    <div className="greeting-user text-center">
                      <div className="profile-vector"><img className="img-fluid" src={profile.avatar} alt="avatar" style={{ maxHeight: 100, maxWidth: 100, borderRadius: '100%' }} /></div>
                      <h4 className="f-w-600"><span id="greeting">{profile.username}</span> <span className="right-circle"> {/* <i className="fa fa-check-circle f-14 middle"></i> */}</span></h4>
                      <p><span> {quote}</span></p>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4 xl-50" lg="12" className="box-col-12">
                <Card className="xl-none" style={{ height: 400, border: 'none' }}>
                  <CardBody className="ecommerce-widget" style={{ border: 'none' }}>
                    <Row>
                      <Col xs="4">
                        <span>{role ? role.role : null}</span>
                      </Col>
                      <Col xs="8">
                        <div className="text-md-right">
                          <ul>
                            <li>{nextRole.role}<span className="product-stts font-primary ml-2">{nextRole.days} dagen<i className="icon-angle-up f-12 ml-1"></i></span></li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <div className="progress-showcase">
                      <span>Progressie tot volgende rang</span>
                      <div className="progress lg-progress-bar">
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: (percentage ?? '0') + '%' }} aria-valuenow={percentage ?? '0'} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <Row style={{ marginTop: 20 }}>
                      <Col xs="12">
                        <h3 className="total-num counter">
                          {playtime.days} dagen<br />
                          {playtime.hours} uur<br />
                          {playtime.minutes} minuten<br />
                          {playtime.seconds} seconden
                        </h3>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="4 xl-50" lg="12" className="alert-sec box-col-12">
                <Card className="bg-img" style={{ backgroundImage: `url(${InfoBackground})`, height: 400 }}>
                  <CardHeader>
                    <div className="header-top">
                      <h5 className="m-0">{"Alert"}  </h5>
                      {/*<div className="dot-right-icon"><i className="fa fa-ellipsis-h"></i></div>*/}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="body-bottom">
                      <h6>  {"Het nieuwe NowayRoleplay Portal"}</h6><span className="font-roboto">{"In jouw portal kun je alle zaken omtrent NowayRoleplay beheren. Momenteel is dit beperkt tot je sancties, bezwaren en unban aanvragen. Wij zijn constant bezig met de ontwikkeling van Portal voor jouw gemak!"} </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <div class="col-sm-6 col-lg-6 col-xl-3">
                <div class="o-hidden card">
                    <div class="bg-primary b-r-4 card-body">
                        <div class="media static-top-widget">
                            <div className="align-self-center text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="8.5" cy="7" r="4"></circle>
                                </svg>
                            </div>
                            <div class="media-body">
                                <span class="m-0">Aantal spelers online</span>
                                <h4 class="mb-0 counter"><span>{players}</span></h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-bg">
                                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="8.5" cy="7" r="4"></circle>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6 col-xl-3">
              <div class="o-hidden card">
                  <div class="bg-primary b-r-4 card-body">
                      <div class="media static-top-widget">
                          <div class="align-self-center text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                             <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                             <circle cx="8.5" cy="7" r="4"></circle>
                             <line x1="20" y1="8" x2="20" y2="14"></line>
                             <line x1="23" y1="11" x2="17" y2="11"></line>
                            </svg>
                          </div>
                          <div class="media-body">
                              <span class="m-0">Meeste spelers vandaag</span>
                              <h4 class="mb-0 counter"><span>{topCount}</span></h4>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-bg">
                                 <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="8.5" cy="7" r="4"></circle>
                                 <line x1="20" y1="8" x2="20" y2="14"></line>
                                 <line x1="23" y1="11" x2="17" y2="11"></line>
                              </svg>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


            <div class="col-sm-6 col-lg-6 col-xl-3">
              <div class="o-hidden card">
                  <div class="bg-primary b-r-4 card-body">
                      <div class="media static-top-widget">
                          <div class="align-self-center text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key">
                               <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                            </svg>
                          </div>
                          <div class="media-body">
                              <span class="m-0">Jouw voertuigen</span>
                              <h4 class="mb-0 counter"><span>{vehicles}</span></h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="col-sm-6 col-lg-6 col-xl-3">
           <div class="o-hidden card">
              <div class="bg-primary b-r-4 card-body">
                 <div class="media static-top-widget">
                    <div class="align-self-center text-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key">
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </div>
                    <div class="media-body">
                       <span class="m-0">Openstaande facturen</span>
                       <h4 class="mb-0 counter">&euro;<span>{billing}</span></h4>
                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-bg">
                         <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                         <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                         <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                        </svg>
                    </div>
                 </div>
              </div>
           </div>
        </div>

          </Row>
        </Container>
       </Fragment>
  );
}

export default Home;
