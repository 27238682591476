import React, { Fragment, useState, useEffect } from 'react';
import { LogIn } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


const Rightbar = (props) => {
  const [moonlight, setMoonlight] = useState(false)

  useEffect(() => {
    if(localStorage.getItem("layout_version") === "dark-only"){
      document.body.className = "dark-only";
      setMoonlight(true);
    }
  }, []);

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light)
      document.body.className = "light"
      localStorage.setItem('layout_version', 'light');
    } else {
      setMoonlight(!light)
      document.body.className = "dark-only"
      localStorage.setItem('layout_version', 'dark-only');
    }
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">

          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}><i className={`fa ${moonlight ? 'fa-lightbulb-o' : 'fa-moon-o'}`}></i></div>
          </li>

          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={props.profile.avatar} alt="" />
              <div className="media-body"><span>{props.profile.username}</span>
                <p className="mb-0 font-roboto">{props.profile.staff ? 'Staff' : 'Gebruiker'} <i className="middle fa fa-angle-down"></i></p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li><LogIn /><span>Log uit</span></li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>

  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.user
  }
}

export default connect(mapStateToProps)(withRouter(Rightbar));
