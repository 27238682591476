import React from 'react';
import {Container,Row,Col,Form,FormGroup,Button,Label,Alert} from 'reactstrap'
import { connect } from 'react-redux';
import { ActionCreators } from '../../redux/actions/user';
import { withRouter, Redirect } from "react-router-dom";
import axios from 'axios';

class Login extends React.Component {

    constructor() {
      super();

      this.state = {
        error: false,
        redirect: null,
        profile: {}
      }

      this.openLogin = this.openLogin.bind(this);
      this.onMessage = this.onMessage.bind(this);
      this.login = this.login.bind(this);
    }

    componentDidMount() {
      window.addEventListener('message', this.onMessage);
    }

    render() {
      if (this.state.redirect) return <Redirect />;

      return (
          <Container fluid={true}>
          <div className="authentication-main mt-0">
            <Row>
              <Col xs="12">
                <div className="login-card">
                  <div>

                    <Alert className="alert-dismissible" isOpen={this.state.error} color="danger" target={"Alert-1"}><strong>{"Error"} </strong> Er ging iets mis toen je probeerde in te loggen
                      <Button className="close" color="default" id={"Alert-1"}><span>×</span></Button>
                    </Alert>

                    <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage"/></a></div>
                    <div className="login-main">
                      <Form className="theme-form">
                        <h4>Login</h4>
                        <FormGroup>
                          <Label className="col-form-label">Enter our majestic portal</Label>
                        </FormGroup>
                        <FormGroup className="mb-0" style={{display: 'flex', justifyContent: 'center'}}>
                          <img alt="steam_login" src="https://cdn.nwrp.nl/s/steam_login_2.png" style={{cursor: 'pointer'}} onClick={this.openLogin} />
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          </Container>
      );
    }

    openLogin() {
      this.popup = window.open(process.env.REACT_APP_API_URL + '/login', 'Login', 'width=500,height=600');
    }

    onMessage(data) {
      let obj = data.data;
      if (obj.source !== 'portal-login-backend') return;

      if (obj.error) {
        this.setState({ error: true });
        return;
      }

      this.login(obj.apiToken);
    }

    login(token) {
      let react_this = this;
      axios.get('/user', {
        headers: {
          authorization: token
        }
      })
      .then(function (response) {
        localStorage.setItem('token', token);
        react_this.props.dispatch(ActionCreators.login(response.data));
        react_this.setState({ redirect: '/' });
      })
      .catch(function (error) {
        react_this.setState({ error: true });
      });
    }

}

const mapStateToProps = (state) => {
  return {
    profile: state.user
  }
}

export default connect(mapStateToProps)(withRouter(Login));
