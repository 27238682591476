import {Home,Shield} from 'react-feather'

export const MENUITEMS = [
    {
        menutitle:"Algemeen",
        Items:[
            {
                title: 'Home', type: 'link', icon: Home, active: false, path: `${process.env.PUBLIC_URL}/`
            }
        ]

    },
    {
        menutitle:"Zelf regelen",
        Items:[
            {
              title: 'Sancties', type: 'sub', icon: Shield, active: false, children: [
                { path: `${process.env.PUBLIC_URL}/sancties`, title: 'Sancties pagina', type: 'link' },
              ]
            },
            // { title: 'Compensatie', type: 'link', icon: DollarSign, path: `${process.env.PUBLIC_URL}/compensatie` }
        ]
    },

]
