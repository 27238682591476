import React, { Fragment, useMemo, useState, useEffect, useCallback } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Card,CardHeader,CardBody,CardFooter,Button, Input } from 'reactstrap';
import SimpleMDE from "react-simplemde-editor";
import swal from 'sweetalert';
import axios from 'axios';
import { marked } from "marked";
import HandshakeSVG from '../../assets/images/handshake.svg';
import GavelSVG from '../../assets/images/gavel.svg';
import Notify from '../../assets/images/notify.svg';

import "easymde/dist/easymde.min.css";


function Unban(props) {

  const [ban, setBan] = useState(null);
  const [objection, setObjection] = useState(localStorage.getItem('smde_' + props.params.id) || '');
  const [evidence, setEvidence] = useState(['']);
  const [mode, setMode] = useState(null);

  useEffect(() => {
    axios.get('/infractions/' + props.params.id + '?with_ban_appeal=true', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
    .then(function (response) {
      setBan(response.data);
      if (response.objection_message) setObjection(null);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [props.params.id]);

  const submit = () => {
    swal({
      title: "Weet je het zeker?",
      text: "Zodra je een unban aanvraag verstuurd kan deze niet meer bewerkt worden!",
      icon: "warning",
      dangerMode: true,
      buttons: ['Nee', {
        text: 'Ja!',
        closeModal: false
      }]
    })
    .then((willSubmit) => {
      if (willSubmit) {
        axios.post('/infractions/' + props.params.id + '/appeal', {
            content: objection,
            evidence,
            mode,
          }, {
            headers: {
              authorization: localStorage.getItem('token')
            },
          })
          .then(function (response) {
            swal({
              title: "Verstuurd!",
              text: "Je unban aanvraag is ingediend, je krijgt spoedig een reactie.",
              icon: "success",
            });

            setBan({
              ...ban,
              objection_status: 'awaiting',
              objection_reaction: null,
              objection_message: objection
            });
            setObjection(null);
            localStorage.removeItem('smde_' + props.params.id);
          })
          .catch(function (error) {
            swal('Er ging iets fout!');
          });
      } else {
        swal.close();
      }
    });
  }

  const onChange = useCallback((value) => {
    setObjection(value);
  }, []);

  const mdeEditorOptions = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      sideBySideFullscreen: false,
      autosave: {
        enabled: true,
        uniqueId: ban ? ban.id : 0
      },
    };
  }, [ban]);

  let termEnded = false;
  let daysLeft = null;

  if (ban) {
    const insertDate = new Date(ban.dateinsert);
    insertDate.setDate(insertDate.getDate() + 24);

    termEnded = new Date() >= insertDate;

    daysLeft = (insertDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24);
  }

  return (
       <Fragment>
       <Breadcrumb parent="Sancties" title="Unban aanvraag"/>
        <Container fluid={true}>
          {
            (ban && ban.objection_message === null & !termEnded && mode === 'apologize') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh',
                  width: '100%',
                }}
                >
                <img alt="happy" src={Notify} style={{ height: 200, marginBottom: 25 }} />
                <h5>Je kunt nog geen unban aanvraag indienen</h5>
                <p>Je kunt over {Math.floor(daysLeft)} dagen een unban aanvraag indienen.</p>
              </div>
            )
          }
          {
            (ban && ban.objection_message === null && !termEnded && mode === null) && (
              <>
                <h6>Kies één van de onderstaande opties</h6>
                <Card style={{ cursor: 'pointer' }} onClick={() => setMode('apologize')}>
                  <CardBody>
                    <img src={HandshakeSVG} alt="handshake" style={{ maxHeight: 30 }} />
                    <span style={{ marginLeft: 15 }}>Ik vind mijn ban terecht, ik wil het graag uitleggen.</span>
                  </CardBody>
                </Card>
                <Card style={{ cursor: 'pointer' }} onClick={() => setMode('object')}>
                  <CardBody>
                    <img src={GavelSVG} alt="gavel" style={{ maxHeight: 30 }} />
                    <span style={{ marginLeft: 15 }}>Ik vind mijn ban onterecht, ik wil hier in tegen beroep gaan.</span>
                  </CardBody>
                </Card>
              </>
            )
          }
          {
            (ban && ban.objection_message === null && (termEnded || mode === 'object')) && (
              <Card>
                <CardHeader>
                  <h5>Unban aanvraag</h5>
                </CardHeader>
                <CardBody
                  style={{ zIndex: 10 }}>
                  <h6>Waarom wil je een unban? Wat heb je geleerd?</h6>
                  <SimpleMDE
                    value={objection || localStorage.getItem('smde_' + ban.id)}
                    onChange={onChange}
                    options={mdeEditorOptions} />

                  <div style={{ marginTop: 20 }}>
                    <h6>Bewijsstukken toevoegen</h6>
                  </div>

                  {
                    evidence.map((value, i) => {
                      let updateEvidence = (e) => {
                        let obj = [ ...evidence ];
                        obj[i] = e.target.value;

                        setEvidence(obj);
                      }

                      let addEvidence = () => {
                        setEvidence([ ...evidence, '' ]);
                      }

                      let removeItem = () => {
                        let obj = evidence.filter((item, x) => {
                          return x !== i;
                        });
                        setEvidence(obj);
                      }

                      return (
                        <div className="form-inline" key={'evidence_' + i} style={{ marginTop: 5 }}>
                          <Input value={value} onChange={updateEvidence} />
                          {
                            i !== 0 && (
                            <Button
                              onClick={removeItem}
                              style={{ marginLeft: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 66, height: 35, padding: 2, boxSizing: 'border-box' }}
                              color="danger">
                              {/* Icon by FontAwesome */}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: 15, width: 15 }} fill="#ffffff">
                                <path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z"/></svg>
                            </Button>
                          )
                          }

                          {
                            i === evidence.length-1 && (
                            <Button
                              onClick={addEvidence}
                              style={{ marginLeft: 5, width: 66, height: 35 }}
                              color="primary">
                              +
                            </Button>
                          )
                          }
                        </div>
                      );
                    })
                  }

                </CardBody>
                <CardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    onClick={submit}
                    color="primary"
                    disabled={objection.length === 0}
                  >
                    Unban aanvraag indienen
                  </Button>
                </CardFooter>
              </Card>
            )
          }

          {
            (ban && ban.objection_message) && (
              <Card>
                <CardBody>
                  <div dangerouslySetInnerHTML={{ __html: marked.parse(ban.objection_message) }}></div>
                </CardBody>
              </Card>
            )
          }

          {
            (ban && ['denied', 'accepted', 'otherunban'].includes(ban.objection_status)) && (
              <Card>
                <CardBody>
                  <div
                    class="ribbon ribbon-danger ribbon-left"
                    style={{ backgroundColor: ban.objection_status === 'denied' ? '#dc3545' : ban.objection_status === 'accepted' ? '#51bb25' : '#0096FF' }}>
                    { ban.objection_status === 'denied' ? 'Afgewezen' :  ban.objection_status === 'accepted' ? 'Goedgekeurd' : 'Andere invulling' }
                  </div>
                  <div style={{ marginTop: 15 }} dangerouslySetInnerHTML={{ __html: marked.parse(ban.objection_reaction) }}></div>
                </CardBody>
              </Card>
            )
          }
        </Container>
       </Fragment>
  );
}

export default Unban;
