import React, { Fragment, useMemo, useState, useEffect, useCallback } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import {Container,Card,CardHeader,CardBody,CardFooter,Button } from 'reactstrap';
import SimpleMDE from "react-simplemde-editor";
import swal from 'sweetalert';
import axios from 'axios';
import { marked } from "marked";

import "easymde/dist/easymde.min.css";


function Objection(props) {

  const [warn, setWarn] = useState(null);
  const [objection, setObjection] = useState(localStorage.getItem('smde_' + props.params.warn) || '');

  useEffect(() => {
    axios.get('/infractions/' + props.params.warn + '?with_objection=true', {
      headers: {
        authorization: localStorage.getItem('token')
      }
    })
    .then(function (response) {
      setWarn(response.data);
      if (response.objection_message) setObjection(null);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [props.params.warn]);

  const submit = () => {
    swal({
      title: "Weet je het zeker?",
      text: "Zodra je een bezwaar verstuurd kan deze niet meer bewerkt worden!",
      icon: "warning",
      dangerMode: true,
      buttons: ['Nee', {
        text: 'Ja!',
        closeModal: false
      }]
    })
    .then((willSubmit) => {
      if (willSubmit) {
        axios.post('/infractions/' + props.params.warn + '/object', {
            content: objection
          }, {
            headers: {
              authorization: localStorage.getItem('token')
            },
          })
          .then(function (response) {
            swal({
              title: "Verstuurd!",
              text: "Je bezwaar is ingediend, je krijgt spoedig een reactie.",
              icon: "success",
            });

            setWarn({
              ...warn,
              objection_status: 'awaiting',
              objection_reaction: null,
              objection_message: objection
            });
            setObjection(null);
            localStorage.removeItem('smde_' + props.params.warn);
          })
          .catch(function (error) {
            swal('Er ging iets fout!');
          });
      } else {
        swal.close();
      }
    });
  }

  const onChange = useCallback((value) => {
    setObjection(value);
  }, []);

  const mdeEditorOptions = useMemo(() => {
    return {
      autofocus: true,
      spellChecker: false,
      sideBySideFullscreen: false,
      autosave: {
        enabled: true,
        uniqueId: warn ? warn.id : 0
      },
    };
  }, [warn]);

  return (
       <Fragment>
       <Breadcrumb parent="Sancties" title="Bezwaar"/>
        <Container fluid={true}>
          {
            (warn && warn.objection_message === null) && (
              <Card>
                <CardHeader>
                  <h5>Bezwaar aantekenen</h5>
                </CardHeader>
                <CardBody
                  style={{ zIndex: 10 }}>
                  <SimpleMDE
                    value={objection || localStorage.getItem('smde_' + warn.id)}
                    onChange={onChange}
                    options={mdeEditorOptions} />

                  </CardBody>
                  <CardFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={submit}
                      color="primary"
                      disabled={objection.length === 0}
                    >
                      Bezwaar indienen
                    </Button>
                  </CardFooter>
              </Card>
            )
          }

          {
            warn && warn.objection_message ? (
              <Card>
                <CardBody>
                  <div dangerouslySetInnerHTML={{ __html: marked.parse(warn.objection_message) }}></div>
                </CardBody>
              </Card>
            ) : null
          }

          {
            warn && ['denied', 'accepted'].includes(warn.objection_status) ? (
              <Card>
                <CardBody>
                  <div
                    class="ribbon ribbon-danger ribbon-left"
                    style={{ backgroundColor: warn.objection_status === 'denied' ? '#dc3545' : '#51bb25' }}>
                    { warn.objection_status === 'denied' ? 'Afgewezen' : 'Goedgekeurd' }
                  </div>
                  <div style={{ marginTop: 15 }} dangerouslySetInnerHTML={{ __html: marked.parse(warn.objection_reaction) }}></div>
                </CardBody>
              </Card>
            ) : null
          }
        </Container>

       </Fragment>
  );
}

export default Objection;
