import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import user from './reducers/user';
import customizer from './reducers/customizer';
import statistics from './reducers/statistics';

const rootReducer = combineReducers({
  user,
  statistics,
  //
  Customizer: customizer
});

const configureStore = () => {
  return createStore(
    rootReducer,
    (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose(applyMiddleware(thunk))
  );
};

export default configureStore;
