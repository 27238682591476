export const Types = {
  LOGIN: 'LOGIN',
  ADD_COSTOMIZER: 'ADD_COSTOMIZER',
  ADD_LAYOUT: 'ADD_LAYOUT',
  ADD_SIDEBAR_TYPES: 'ADD_SIDEBAR_TYPES',
  ADD_SIDEBAR_SETTINGS: 'ADD_SIDEBAR_SETTINGS',
  ADD_COLOR: 'ADD_COLOR',
  ADD_MIX_BACKGROUND_LAYOUT: 'ADD_MIX_BACKGROUND_LAYOUT',
  ROUTER_ANIMATION: 'ROUTER_ANIMATION',
  SET_THEME: 'SET_THEME',
  SET_STATISTICS: 'SET_STATISTICS',
}
